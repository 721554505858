<template>
  <a-card>
    <div class="table-page-search-wrapper">
      <a-page-header
        style="border: 1px solid rgb(235, 237, 240); margin-bottom: 35px"
        :title="total"
        :sub-title="title"
        @back="() => $router.go(-1)"
      >
      </a-page-header>
      <div class="table-operator">
        <a-button type="primary" icon="plus" @click="handleAdd" v-action:add>新建</a-button>
      </div>
    </div>
    <a-table
      ref="table"
      rowKey="id"
      size="middle"
      :loading="loading"
      :columns="columns"
      :data-source="recordList"
      :pagination="false"
      :bordered="false"
    >
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <div slot="money_render" slot-scope="text">
        {{ (text / 100).toFixed(2) | numberFormat }}
      </div>
    </a-table>
    <reset-form
      ref="createModal"
      :visible="visible"
      :loading="confirmLoading"
      @cancel="handleResetCancel"
      @ok="handleResetOk" />
  </a-card>
</template>

<script>
import { STable } from '@/components'
import { Dictionaries } from '@/common/AllConstant'
import ResetForm from '../modules/ResetForm'
import { single_electricity_meter_create } from '@/api/electricity'
const { single_electricity_meter_list } = require('@/api/electricity')

export default {
  name: 'TableList',
  components: {
    ResetForm,
    STable
  },
  data () {
    return {
      key: 1,
      loading: false,
      dictionaries: Dictionaries,
      // 创建窗口控制
      recordList: [],
      title: '',
      total: '余额： 0',
      visible: false,
      confirmLoading: false,
      isAvailableDir: { 1: '是', 0: '否' },
      // 查询参数
      queryParam: { is_available: true },
      // 表头
      columns: [
        {
          title: '序号',
          width: 50,
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '金额',
          dataIndex: 'payment',
          scopedSlots: { customRender: 'money_render' },
          ellipsis: true
        },
        {
          title: '类型',
          dataIndex: 'type',
          align: 'center',
          customRender: (text) => text === 1 ? '充值' : text === -1 ? '扣费' : '补充'
        },
        {
          title: '时间',
          dataIndex: 'time',
          ellipsis: true
        }
        // {
        //   title: '操作',
        //   dataIndex: 'action',
        //   width: '150px',
        //   scopedSlots: { customRender: 'action' }
        // }
      ],
      // 加载数据方法 必须为 Promise 对象
      selectedRowKeys: [],
      selectedRows: [],

      // custom table alert & rowSelection
      options: {
        alert: { show: true, clear: () => { this.selectedRowKeys = [] } },
        rowSelection: {
          selectedRowKeys: this.selectedRowKeys,
          onChange: this.onSelectChange
        }
      },
      optionAlertShow: false
    }
  },
  created () {
    this.tableOption()
  },
  methods: {
    tableOption () {
      if (!this.optionAlertShow) {
        this.options = {
          alert: { show: true, clear: () => { this.selectedRowKeys = [] } },
          rowSelection: {
            selectedRowKeys: this.selectedRowKeys,
            onChange: this.onSelectChange
          }
        }
        this.optionAlertShow = true
      } else {
        this.options = {
          alert: false,
          rowSelection: null
        }
        this.optionAlertShow = false
      }
    },
    getSingleElectricityMeterData () {
      this.title = '电表' + this.$route.query.number
      single_electricity_meter_list(this.$route.params.id).then(res => {
        console.log(res)
        this.recordList = []
        let inTotal = 0
        let outTotal = 0
        let resetTotal = 0
        if (res.data.recharges) {
          res.data.recharges.forEach(item => {
            const record = {
              payment: item.actual_payment,
              time: item.create_time,
              type: 1
            }
            inTotal += item.actual_payment
            this.recordList.push(record)
          })
        }
        if (res.data.chargings) {
          res.data.chargings.forEach(item => {
            const record = {
              payment: -item.amount,
              time: item.create_time,
              type: -1
            }
            outTotal += item.amount
            this.recordList.push(record)
          })
        }
        if (res.data.resets) {
          res.data.resets.forEach(item => {
            const record = {
              payment: item.value,
              time: item.create_time,
              type: 2
            }
            resetTotal += item.value
            this.recordList.push(record)
          })
        }
        this.total = '余额：' + ((inTotal - outTotal + resetTotal) * 0.01).toFixed(2)
      })
    },
    handleAdd () {
      this.visible = true
    },
    handleResetCancel () {
      this.visible = false
      const form = this.$refs.createModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handleResetOk () {
      console.log(this.$route.params.id)
      const id = this.$route.params.id
      const form = this.$refs.createModal.form
      this.confirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          console.log('values', values)
          // 新增
          const requestData = {
            'value': values.value * 100
          }
          single_electricity_meter_create(id, requestData).then(res => {
            this.visible = false
            this.confirmLoading = false
            // 重置表单数据
            form.resetFields()
            // 刷新表格
            this.getSingleElectricityMeterData()
          })
        } else {
          this.confirmLoading = false
        }
      })
    }
  },
  mounted () {
    this.getSingleElectricityMeterData()
  }
}
</script>
